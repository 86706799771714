import './StreamArt.css';

import googleButton from '../../assets/stream/googlebutton.png';
import appStoreButton from '../../assets/stream/appstorebutton.png';
import streamArt from '../../assets/stream/streamArt.webp';


import { androidtv,appletv,samsungtv,sonytv} from  '../../assets/stream/index';
import { playStoreLink, appStoreLink } from '../../constants/linkscontants';

const StreamArt = () => {
    return (
        <div className="streamArt-section">
            <div className="streamArt-section__left">
                <span className='streamArt-section__title_1'>
                    Stream your art<br />
                    <span className='streamArt-section__title_2'>
                        anywhere anytime<br />on your TV
                    </span>
                </span>


<h3 className='streamArt-section-subtitle'>Compatible on any TV</h3>

<div className='tv-brands-container'><img src={samsungtv} alt='samsungtv' className="tv-logos"/> <img src={appletv} alt='appletv' className='tv-logos'/> <img src={sonytv} alt='sonytv' className='tv-logos'/> <img src={androidtv} alt='androidtv' className='tv-logos'/> </div>

<h3 className='streamArt-section-subtitle'>Compatible on any Android & iOS devices</h3>
                <div className='StreamArt__appButtons'>
                    <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                        <img src={googleButton} alt='Google Play button' className='StreamArt__appButton' />
                    </a>

                    <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                        <img src={appStoreButton} alt='App Store button' className='StreamArt__appButton' />
                    </a>
                </div>
            </div>

            <img src={streamArt} alt="Stream Art" className="streamArt-section__right" />
        </div>
    );
};

export default StreamArt;
