import './HomeGuide.css'

import {  consumerGuide,artistGuide } from '../../../constants/arrayconstants';

import { useState } from 'react';
function HomeGuide(){

    const [isConsumer,setIsConsumer]=useState(true);
    
    
 
    
    
const renderGuideSteps = (guideList) => {
    return guideList.map((guide, index) => (
      <div className="stepguide" key={index}>
        <div className="stepguide__header">
          <div className="stepguide__header__count" >{index + 1}</div>
          <p className="role-stepguide__header__title"   style={ {color:guide.borderColor} }>{guide.title}</p>
        </div>

        <div className='role-stepguide__header__content' > <img  src={guide.image} alt={guide.title} className="stepguide__card"   >
     
     </img>
     <div className="role-stepguide__description">{guide.description}</div></div>
       
      </div>
    ));
  };




    return (
    <div  className="homeguide__section">

<div className="homeguide__header">
<p className="homeguide__header__title">Step by step guide</p>
<div className="homeguide__header__button"> 
<div className={`${isConsumer ? "homeguide__header__button_enable" : "homeguide__header__button_disabled"}`} onClick={()=>{
    setIsConsumer(true);
}} >For Consumers</div>
<div className={`${!isConsumer ? "homeguide__header__button_enable" : "homeguide__header__button_disabled"}`} onClick={()=>{
    setIsConsumer(false);
}}>For Artist</div>

</div>


</div>

    <div className="homeguide__body">
    {   isConsumer ? renderGuideSteps(consumerGuide) : renderGuideSteps(artistGuide)}
        </div>


{/* <div className='trybutton' >Try Now</div> */}

    </div>);
}


export default  HomeGuide;